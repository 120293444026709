import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row } from '../../components/Grid';
import EpisodePlayer from '../../components/EpisodePlayer';

const VideoTopicSeries = ({ data }) => {
  return (
    <Layout title="Video Topic Series" className="video-topic-series">
      <Section>
        <Container fullWidth>
          <Row>
            <h2>Sustainability: The Human Factor - Video Topic Series</h2>
            <p>
              Sustainability: The Human Factor is a 3-part Video Topic Series
              that showcases the human imagination, ingenuity, and passion
              behind the science of sustainability. Each video features a young
              changemaker who is sparking sustainable action at the local level,
              along with professional insights from a Trane Technologies thought
              leader working to innovate more sustainable ways of life.
            </p>
          </Row>
        </Container>
      </Section>
      <EpisodePlayer
        episodes={data.allVideoTopicSeriesYaml.edges.map((edge) => edge.node)}
      />
      {/* <Callout
          target="_blank"
          to="https://surveys.discoveryeducation.com/s3/Sustainable-Futures-Virtual-Field-Trip-Survey-short"
        >
          Did you watch the Video Topic Series? Let us know what you thought!
        </Callout> */}
    </Layout>
  );
};

export default VideoTopicSeries;

export const query = graphql`
  query VideoTopicSeriesQuery {
    allVideoTopicSeriesYaml(sort: { order: [ASC], fields: [index] }) {
      edges {
        node {
          title
          guidSrc
          posterSrc
          duration
          thmbDes
          descriptionTitle
          description
          mainActivities {
            label
            path
          }
          familyConnectionDesc
          familyConnectionActions {
            label
            path
          }
          conversationStarterDesc
          starterOne
          starterTwo
          starterThree
          subfooterImage
          subfooterQuote
          subfooterName
          subfooterTitle
          subfooterCompany
          subfooterClassName
        }
      }
    }
  }
`;
