import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Video from '../Video';
import Image from '../Image';
import Resource from '../Resource';
import Action from '../Resource/Action';
import './styles.scss';
import Section from '../Section';
import Quote from '../Quote';

const EpisodePlayer = ({ episodes, playerLabel, className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const viewerRef = useRef(null);

  // EPISODE STACK
  const renderEpisodes = () => (
    <>
      {episodes.map((episode, index) => (
        <button
          key={index}
          className="episode-player__episode"
          onClick={() => {
            setActiveIndex(index);
            if (viewerRef.current.getBoundingClientRect()['top'] < 0) {
              viewerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div
            className={classNames(
              index === activeIndex && 'episode-player__episode--active'
            )}
          >
            <Image filename={episode.posterSrc} className="episode__image" />
          </div>
          <div className="episode-player__description">
            <h5
              className={classNames(
                'episode-player__episode-title',
                index === activeIndex && 'episode-player__episode-title--active'
              )}
            >
              {episode.title}
            </h5>
            <p className="episode-player__episode-duration">
              {episode.duration}
            </p>
            <p className="episode-player__episode-desc">{episode.thmbDes}</p>
          </div>
        </button>
      ))}
    </>
  );

  const renderCurrentVideo = () => {
    const episode = episodes[activeIndex];
    return (
      <Video
        guidSrc={episode.guidSrc}
        poster={episode.posterSrc}
        className="video-player"
        title={episode.title}
      />
    );
  };

  //CURRENT EPISODE-Large vid
  const renderCurrentData = () => {
    const episode = episodes[activeIndex];
    return (
      <>
        <Container className="pl-0">
          <Row>
            <Column size={7} className="vts__video-text">
              <div className="episode-player__intro pb-2">
                <h3>{episode.descriptionTitle}</h3>
                <p>{episode.description}</p>
                {episode.mainActivities && (
                  <div className="episode-player__actions">
                    {episode.mainActivities.map((action, i) => (
                      <Action
                        key={i + 'mainActivities'}
                        link={action.path}
                        label={action.label}
                        target="_blank"
                      />
                    ))}
                  </div>
                )}
                <h4 className="pt-2">FAMILY CONNECTION</h4>
                <p>{episode.familyConnectionDesc}</p>
                {episode.familyConnectionActions && (
                  <div className="episode-player__actions">
                    {episode.familyConnectionActions.map((action, i) => (
                      <Action
                        key={i + 'familyConnectionActions'}
                        link={action.path}
                        label={action.label}
                        target="_blank"
                      />
                    ))}
                  </div>
                )}
                <h4 className="pt-2">CLASSROOM CONVERSATION STARTERS</h4>
                <p>{episode.conversationStarterDesc}</p>
                <ul>
                  <li>{episode.starterOne}</li>
                  <li>{episode.starterTwo}</li>
                  <li>{episode.starterThree}</li>
                </ul>
              </div>
            </Column>
            <Column large={4}>
              <Resource
                contentClassOverride="vts-resource-content"
                className="vts-resource-container"
                subject="EDUCATOR GUIDE"
                img="img-vts-edguide@2x.jpg"
                title="Sustainability: The Human Factor"
                description="Kickstart conversation around the concepts discussed in the video with this companion Educator Guide, featuring helpful background info and additional resources to extend learning."
                actions={[
                  {
                    label: 'Educator Guide',
                    download:
                      '/pdfs/SustainableFutures-VTSEdGuide-Sustainability.pdf',
                    linkFromImg: true,
                  },
                ]}
              />
            </Column>
          </Row>
          <Row>
            {/* <Column className="vts__surveyBanner">
            <p>
              Did you watch the virtual Topic Series? Let us know what you
              thought
            </p>
            <Button
              link
              className="vts__surveyLink"
              to="https://surveys.discoveryeducation.com/collab/CEP-Post-Virtual-Field-Trip-Survey-Short-Version"
              rel="noopener noreferrer"
              target="__blank"
            >
              Fill out survey
              <Icon name="arrowright" marginLeft />
            </Button>
          </Column> */}
          </Row>
        </Container>
      </>
    );
  };

  const renderCurrentSubfooter = () => {
    const episode = episodes[activeIndex];
    return (
      <>
        <Image filename={episode.subfooterSrc} className="subfooter__bg" />
        <Image
          filename="img-quote-overlay-1.png"
          className="subfooter__overlay-img"
        />
        <Container className="subfooter__overlay-text pt-2">
          <Row>
            <Column lg={6}>
              <div className="subfooter__quote">{episode.subfooterQuote}</div>
              <div className="subfooter__attribution">
                {episode.subfooterStudent}
              </div>
              <div className="subfooter__job-title">
                {episode.subfooterOccupation}
              </div>

              <div className="subfooter__link pt-1">
                <Link to="/classroom-resources/video-topic-series">
                  LEARN MORE ABOUT SEL EXPERIENCES IN THE CLASSROOM
                </Link>
              </div>
            </Column>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <>
      <Section className="pt-0">
        <Container>
          <Container className={`episode-player ${className}`}>
            <div className="episode-player__content-wrapper">
              <div ref={viewerRef} className="episode-player__video-wrapper">
                <div className="episode-player__video">
                  {renderCurrentVideo()}
                </div>
                <div className="episode-player__video-desktop-spacer"></div>
              </div>
              <div className="episode-player__current-data">
                {renderCurrentData()}
              </div>
              <div className="episode-player__episode-wrapper">
                <div className="episode-player__episode-desktop-spacer"></div>
                <div className="episode-player__episodes">
                  {renderEpisodes()}
                </div>
              </div>
            </div>
          </Container>
        </Container>
      </Section>

      <Quote
        quote={episodes[activeIndex].subfooterQuote}
        img={episodes[activeIndex].subfooterImage}
        name={episodes[activeIndex].subfooterName}
        title={episodes[activeIndex].subfooterTitle}
        company={episodes[activeIndex].subfooterCompany}
        className={`${episodes[activeIndex].subfooterClassName} mt-2`}
      />
      {/* <Section className={classNames('subfooter', 'pt-0', 'pb-0')}>
        {renderCurrentSubfooter()}
      </Section> */}
    </>
  );
};

EpisodePlayer.propTypes = {
  episodes: PropTypes.array.isRequired,
  playerLabel: PropTypes.string,
  className: PropTypes.string,
};

EpisodePlayer.defaultProps = {
  playerLabel: '',
  className: '',
};

export default EpisodePlayer;
